import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Box,
  Text,
  Button,
  Spinner,
  Alert,
  AlertIcon,
  useToast,
  Progress,
  Icon,
  Flex,
  Center,
} from "@chakra-ui/react";
import Top10Users from "./Top10Users";
import { Col, Row } from "react-bootstrap";
import StatisticsWidget from "../../../components/StatisticsWidget";
import PageTitle from "../../../components/PageTitle";
import QuotaCheck from "./QuotaCheck";
import { APICore } from "../../../helpers/api/apiCore";
import { useLocation } from "react-router-dom";
import { FaMoneyCheck } from "react-icons/fa";

const api = new APICore();

interface RaffleProgressProps {
  totalNumbersSold: number;
  raffleType: "CEM_MIL" | "UM_MILHAO" | "DEZ_MILHOES"; // Agora o tipo de `raffleType` é uma string com os valores possíveis
}

function RaffleDetails() {
  const { raffleId } = useParams(); // Captura o ID da rota
  const [raffle, setRaffle] = useState(null);
  const [error, setError] = useState(false);
  const location = useLocation();
  const raffleTitle = location.state?.title || "Título não disponível";
  const raffleType = location.state?.type || "Progresso da rifa não disponível";
  const [isLoading, setIsLoading] = useState(false); // Adiciona o estado de loading

  const toast = useToast();
  const totalNumbers = (() => {
    switch (raffleType) {
      case "MIL":
        return 1000; // 100.000 números
      case "DEZ_MIL":
        return 10000; // 1.000.000 números
      case "CEM_MIL":
        return 100000; // 100.000 números
      case "UM_MILHAO":
        return 1000000; // 1.000.000 números
      case "DEZ_MILHOES":
        return 10000000; // 10.000.000 números
      default:
        return 0; // Caso não seja um dos valores conhecidos
    }
  })();

  useEffect(() => {
    const fetchRaffleDetails = () => {
      api
        .get(`/bancas/raffles/${raffleId}/info`)
        .then((response) => {
          const raffleDetails = response.data;

          if (!raffleDetails) {
            // Caso não haja dados na resposta
            throw new Error("Rifa não encontrada.");
          }

          setRaffle(raffleDetails);
        })
        .catch((error) => {
          toast({
            title: "Erro ao carregar detalhes da rifa.",
            description: error,
            status: "error",
            duration: 5000,
            isClosable: true,
          });

          setError(true);
        });
    };

    fetchRaffleDetails();
  }, [raffleId]);

  if (error) {
    return (
      <Alert status="error">
        <AlertIcon />
        Erro ao carregar os detalhes da rifa.
      </Alert>
    );
  }

  if (!raffle) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  const formattedTotalNumbers = new Intl.NumberFormat("pt-BR").format(
    totalNumbers
  );

  const handleCloseRaffle = async () => {
    if (
      window.confirm(
        "Esta ação é completamente *IRREVERSÍVEL*. \r\n Deseja realmente fechar a rifa?"
      ) === false
    )
      return;

    setIsLoading(true); // Define que o loading começou

    await api
      .delete(`/bancas/raffles/${raffleId}/close`)
      ?.then((response) => {
        setRaffle((prevRaffle) => ({
          ...prevRaffle,
          status: "CLOSED",
        }));

        toast({
          title: "Rifa encerrada com sucesso.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: "Erro ao encerrar a rifa.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoading(false); // Finaliza o estado de loading
      });
  };

  return (
    <Box p={5}>
      <PageTitle
        breadCrumbItems={[
          {
            label: "Rifinha",
            path: "/bancas/raffles/",
          },
          {
            label: "Detalhes",
            path: `/bancas/raffles/${raffleId}/info`,
            active: true,
          },
        ]}
        title={raffleTitle}
      />

      <Box>
        <Text mb={2} fontWeight="bold">
          Progresso da Rifa (
          {((raffle?.totalNumbersSold / totalNumbers) * 100).toFixed(2)}%)
        </Text>
        <Progress
          value={(raffle?.totalNumbersSold / totalNumbers) * 100} // Cálculo do progresso em porcentagem
          colorScheme="teal"
          size="lg"
          hasStripe
          isAnimated
          border={"1px solid #000"}
        />
        <Box mt={2} display="flex" justifyContent="space-between">
          <Text fontWeight={"bold"} fontSize="sm" mb={0}>
            {raffle?.totalNumbersSold} vendidos
          </Text>
          <Text fontWeight={"bold"} fontSize="sm" mb={0}>
            {formattedTotalNumbers} total de números
          </Text>
        </Box>
      </Box>

      <Flex justifyContent={"right"} my={2}>
        <Button
          colorScheme="red"
          leftIcon={isLoading ? null : <Icon as={FaMoneyCheck} />}
          isLoading={isLoading}
          loadingText="Finalizando..."
          onClick={handleCloseRaffle}
          isDisabled={isLoading || raffle?.status === "CLOSED"}
        >
          {raffle?.status === "CLOSED"
            ? "VENDAS ENCERRADAS!"
            : "FINALIZAR VENDA DE COTAS"}
        </Button>
      </Flex>

      <Col md={12}>
        <Row>
          <Col md={6}>
            <StatisticsWidget
              variant="danger"
              counterOptions={{
                prefix: "R$ ",
                preserveValue: true,
                decimal: ",",
                separator: ".",
              }}
              description="Valor total"
              stats={raffle?.totalNumbersPrice || 0}
              icon="fe-award"
            />
            <StatisticsWidget
              variant="warning"
              counterOptions={{}}
              description="Quantidade"
              stats={raffle?.totalNumbersSold || 0}
              icon="fe-bar-chart-line"
            />
            <QuotaCheck raffleId={raffle.raffleId} />
          </Col>
          <Col md={6}>
            <Top10Users raffleId={raffle.raffleId} />
          </Col>
        </Row>
      </Col>
    </Box>
  );
}

export default RaffleDetails;
