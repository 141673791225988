import React, { useState, useEffect } from "react";
import { Table, Card, Spinner } from "react-bootstrap";
import { APICore } from "../../../helpers/api/apiCore";
import { useToast } from "@chakra-ui/react";

interface User {
  unitId: string;
  totalNumbers: number;
}

interface Top10UsersProps {
  raffleId: string;
}

const api = new APICore();

const Top10Users: React.FC<Top10UsersProps> = ({ raffleId }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  useEffect(() => {
    const fetchTop10Users = () => {
      setLoading(true);

      api
        .get(`/bancas/raffles/top/${raffleId}`)
        .then((response) => {
          const data = response.data;

          // Garantir que `content` exista e seja um array
          if (!data.content || !Array.isArray(data.content)) {
            throw new Error("A propriedade 'content' não é um array.");
          }

          setUsers(data.content);
        })
        .catch((error) => {
          // Exibir um Toast de erro
          toast({
            title: "Erro ao carregar os dados dos top compradores",
            description: error,
            status: "error",
            duration: 5000,
            isClosable: true,
          });

          setError("Erro ao carregar os dados dos top 10 usuários.");
        })
        .finally(() => {
          setLoading(false); // Finaliza o carregamento
        });
    };

    fetchTop10Users();
  }, [raffleId]);

  if (loading) {
    return (
      <div className="text-center my-4">
        <Spinner animation="border" />
      </div>
    );
  }

  if (error) {
    return <div className="text-danger text-center">{error}</div>;
  }

  return (
    <Card className="shadow-sm" style={{ border: "1px solid #000" }}>
      <Card.Body>
        <h5 className="card-title text-center mb-4 fs-3">Top compradores</h5>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th className="text-center">Posição</th>
              <th className="text-center">Unidade</th>
              <th className="text-center">Total de Cotas</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={user.unitId}>
                <td className="text-center fw-bold">{index + 1}</td>
                <td className="text-center">{user.unitId}</td>
                <td className="text-center">{user.totalNumbers}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default Top10Users;
