import React from "react";
import { Route, Navigate, RouteProps } from "react-router-dom";

// COMPONENTS
import PrivateRoute from "./PrivateRoute";
import Unidades from "../pages/bancas/unidades/Unidades";
import UnidadesCreate from "../pages/bancas/unidades/UnidadesCreate";
import Regions from "../pages/bancas/regions/Regions";
import RegionsCreate from "../pages/bancas/regions/RegionsCreate";
import PixAccounts from "../pages/bancas/pixAccounts/PixAccounts";
import PixAccountsCreate from "../pages/bancas/pixAccounts/PixAccountsCreate";
import PaymentTablesCreate from "../pages/bancas/paymentTables/PaymentTablesCreate";
import PaymentTables from "../pages/bancas/paymentTables/PaymentTables";
import UserBase from "../pages/bancas/user/UserBase";
import UserBaseCreate from "../pages/bancas/user/UserBaseCreate";
import Promotions from "../pages/bancas/promotions/promotions";
import PromotionsCreate from "../pages/bancas/promotions/promotionsCreate";
import Fazendinha from "../pages/bancas/fazendinha/Fazendinha";
import Recargas from "../pages/bancas/recargas/Recarga";
import Geral from "../pages/bancas/geral/Geral";
import Pules from "../pages/bancas/Pules/Pules";
import Financial from "../pages/bancas/financeiro/Financeiro";
import Premios from "../pages/bancas/premios/Premios";
import Devices from "../pages/bancas/devices/Devices";
import DevicesCreate from "../pages/bancas/devices/DevicesCreate";
import Limits from "../pages/bancas/limits/limits";
import Pass from "../pages/bancas/pass/pass";
import Adjusts from "../pages/bancas/financeiro/Ajustes";
import SystemLimits from "../pages/sistema/limits/limits";
import MorePlayeds from "../pages/bancas/more-playeds/morePlayeds";
import RepasseRegistradas from "../pages/bancas/RepassesRegistradas/Pules";
import Invoices from "../pages/sistema/invoices/Invoices";
import FarmPules from "../pages/bancas/farmPules/FarmPules";
import ReportLottery from "../pages/bancas/report-lotteries/ReportLottery";
import UnidadesReport from "../pages/bancas/units-report/UnidadesReport";
import Saques from "../pages/bancas/saques/Saques";
import FarmReport from "../pages/bancas/fazendinha/FarmReport";
import RouletteReport from "../pages/bancas/roletinha/RouletteReport";
import Rifa from "../pages/bancas/rifa/Rifa";

import Configs from "../pages/bancas/configs/Configs";
import Cassino from "../pages/bancas/cassino/Cassino";
import ScratchReport from "../pages/bancas/raspadinha/ScratchReport";
import BaseInvoice from "../pages/sistema/base/BaseInvoice";
import Support from "../pages/sistema/support/support";
import UnitsInactives from "../pages/bancas/units-inactives/UnitsInactives";
import RaffleDetails from "../pages/bancas/rifa/RaffleDetails";

// AUTH
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));

// BASE
const Bases = React.lazy(() => import("../pages/sistema/base/Base"));
const BaseCreate = React.lazy(() => import("../pages/sistema/base/BaseCreate"));

// LOTTERY
const Lotteries = React.lazy(() => import("../pages/sistema/lottery/Lottery"));
const LotteryCreate = React.lazy(
  () => import("../pages/sistema/lottery/LotteryCreate")
);

// LOTTERY CATEGORIES
const LotteryCategories = React.lazy(
  () => import("../pages/sistema/lottery/category/LotteryCategory")
);
const LotteryCategoryCreate = React.lazy(
  () => import("../pages/sistema/lottery/category/LotteryCategoryCreate")
);

// EXTRACTION
const Extractions = React.lazy(
  () => import("../pages/sistema/extraction/Extraction")
);
const ExtractionCreate = React.lazy(
  () => import("../pages/sistema/extraction/ExtractionCreate")
);

// USER
const Users = React.lazy(() => import("../pages/sistema/user/User"));
const UserCreate = React.lazy(() => import("../pages/sistema/user/UserCreate"));

// DASHBOARD
const Dashboard1 = React.lazy(() => import("../pages/dashboard/Dashboard1/"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// BASE ROUTES
const baseRoutes: RoutesProps = {
  path: "/base",
  name: "Bases",
  header: "Navigation",
  children: [
    {
      path: "/",
      name: "Root",
      element: <Navigate to="/bases" />,
      route: PrivateRoute,
    },
    {
      path: "/bases",
      name: "Listagem",
      element: <Bases />,
      route: PrivateRoute,
    },
    {
      path: "/bases/new",
      name: "Adicionar Novo",
      element: <BaseCreate />,
      route: PrivateRoute,
    },
    {
      path: "/bases/update/:id",
      name: "Atualizar",
      element: <BaseCreate />,
      route: PrivateRoute,
    },
    {
      path: "/bases/invoice/:id",
      name: "Invoice",
      element: <BaseInvoice />,
      route: PrivateRoute,
    },
  ],
};

// HOME
const homeRoutes: RoutesProps = {
  path: "*",
  name: "NotFound",
  header: "Navigation",
  element: <Navigate to="dashboard" />,
  route: PrivateRoute,
};

// DASHBOARD ROUTES
const dashboardRoutes: RoutesProps = {
  path: "/",
  name: "Root",
  header: "Navigation",
  element: <Dashboard1 />,
  route: PrivateRoute,
  children: [
    {
      path: "/dashboard",
      name: "Dashboard",
      element: <Dashboard1 />,
      route: PrivateRoute,
    },
  ],
};

// BANCA ROUTES
const bancaRoutes: RoutesProps[] = [
  {
    path: "/base/units",
    name: "Unidades",
    header: "Navigation",
    element: <Unidades />,
    route: PrivateRoute,
    children: [
      {
        path: "/base/units/new",
        name: "Unidades Create",
        element: <UnidadesCreate />,
        route: PrivateRoute,
      },
      {
        path: "/base/units/update/:id",
        name: "Unidades Edit",
        element: <UnidadesCreate />,
        route: PrivateRoute,
      },
    ],
  },
  {
    path: "/base/regions",
    name: "Regiões",
    header: "Navigation",
    element: <Regions />,
    route: PrivateRoute,
    children: [
      {
        path: "/base/regions/new",
        name: "Regions Create",
        element: <RegionsCreate />,
        route: PrivateRoute,
      },
      {
        path: "/base/regions/update/:id",
        name: "Regions Edit",
        element: <RegionsCreate />,
        route: PrivateRoute,
      },
    ],
  },
  {
    path: "/base/devices",
    name: "Dispositivos",
    header: "Navigation",
    element: <Devices />,
    route: PrivateRoute,
    children: [
      {
        path: "/base/devices/new",
        name: "Devices Create",
        element: <DevicesCreate />,
        route: PrivateRoute,
      },
      {
        path: "/base/devices/update/:id",
        name: "Devices Edit",
        element: <DevicesCreate />,
        route: PrivateRoute,
      },
    ],
  },
  {
    path: "/base/promotions",
    name: "Promoções",
    header: "Navigation",
    element: <Promotions />,
    route: PrivateRoute,
    children: [
      {
        path: "/base/promotions/new",
        name: "Promotions Create",
        element: <PromotionsCreate />,
        route: PrivateRoute,
      },
      {
        path: "/base/promotions/update/:id",
        name: "Promotions Edit",
        element: <PromotionsCreate />,
        route: PrivateRoute,
      },
    ],
  },
  {
    path: "/base/roulette",
    name: "Roletinha",
    header: "Navigation",
    element: <RouletteReport />,
    route: PrivateRoute,
  },
  {
    path: "/base/scratch",
    name: "Raspadinha",
    header: "Navigation",
    element: <ScratchReport />,
    route: PrivateRoute,
  },
  {
    path: "/base/farm",
    name: "Fazendinha",
    header: "Navigation",
    element: <Fazendinha />,
    route: PrivateRoute,
    children: [
      {
        path: "/base/farm/pules",
        name: "Farm Pules",
        element: <FarmPules />,
        route: PrivateRoute,
      },
      {
        path: "/base/farm/report",
        name: "Farm Report",
        element: <FarmReport />,
        route: PrivateRoute,
      },
    ],
  },

  {
    path: "/base/raffles",
    name: "Prêmios",
    header: "Navigation",
    element: <Rifa />,
    route: PrivateRoute,
  },
  {
    path: "/base/raffles/:raffleId/info",
    name: "Detalhes da Rifa",
    element: <RaffleDetails />,
    route: PrivateRoute,
  },
  {
    path: "/base/withdraw",
    name: "Saques",
    header: "Navigation",
    element: <Saques />,
    route: PrivateRoute,
  },
  {
    path: "/base/recharge",
    name: "Recargas",
    header: "Navigation",
    element: <Recargas />,
    route: PrivateRoute,
  },
  {
    path: "/base/pules",
    name: "Pules",
    header: "Navigation",
    element: <Pules />,
    route: PrivateRoute,
  },
  {
    path: "/base/pass-dashboard",
    name: "Registradas",
    header: "Navigation",
    element: <RepasseRegistradas />,
    route: PrivateRoute,
  },
  {
    path: "/base/geral",
    name: "Geral",
    header: "Navigation",
    element: <Geral />,
    route: PrivateRoute,
  },
  // {
  //   path: "/base/cassino",
  //   name: "Cassino",
  //   header: "Navigation",
  //   element: <Cassino />,
  //   route: PrivateRoute,
  // },
  {
    path: "/base/financial",
    name: "Financeiro",
    header: "Navigation",
    element: <Financial />,
    route: PrivateRoute,
  },
  {
    path: "/base/adjusts",
    name: "Extrato",
    header: "Navigation",
    element: <Adjusts />,
    route: PrivateRoute,
  },
  {
    path: "/base/awards",
    name: "Prêmios",
    header: "Navigation",
    element: <Premios />,
    route: PrivateRoute,
  },
  {
    path: "/base/pix-accounts",
    name: "PixAccounts",
    header: "Navigation",
    element: <PixAccounts />,
    route: PrivateRoute,
    children: [
      {
        path: "/base/pix-accounts/new",
        name: "Pix Accounts Create",
        element: <PixAccountsCreate />,
        route: PrivateRoute,
      },
      {
        path: "/base/pix-accounts/update/:id",
        name: "Pix Accounts Edit",
        element: <PixAccountsCreate />,
        route: PrivateRoute,
      },
    ],
  },
  {
    path: "/base/payment-tables",
    name: "PaymentTables",
    header: "Navigation",
    element: <PaymentTables />,
    route: PrivateRoute,
    children: [
      {
        path: "/base/payment-tables/new",
        name: "Payment Table Create",
        element: <PaymentTablesCreate />,
        route: PrivateRoute,
      },
      {
        path: "/base/payment-tables/update/:id",
        name: "Payment Tables Edit",
        element: <PaymentTablesCreate />,
        route: PrivateRoute,
      },
      {
        path: "/base/payment-tables/duplicate/:id",
        name: "Payment Tables Duplicate",
        element: <PaymentTablesCreate />,
        route: PrivateRoute,
      },
    ],
  },
  {
    path: "/base/limits",
    name: "Limits",
    header: "Navigation",
    element: <Limits />,
    route: PrivateRoute,
  },
  {
    path: "/base/pass",
    name: "Repasses",
    header: "Navigation",
    element: <Pass />,
    route: PrivateRoute,
  },
  {
    path: "/base/users",
    name: "UserBase",
    header: "Navigation",
    element: <UserBase />,
    route: PrivateRoute,
    children: [
      {
        path: "/base/users/new",
        name: "User Base Create",
        element: <UserBaseCreate />,
        route: PrivateRoute,
      },
      {
        path: "/base/users/update/:id",
        name: "User Base Edit",
        element: <UserBaseCreate />,
        route: PrivateRoute,
      },
    ],
  },
  {
    path: "/base/configs",
    name: "Configs",
    header: "Navigation",
    element: <Configs />,
    route: PrivateRoute,
  },
  {
    path: "/base/reports",
    name: "UserBase",
    header: "Navigation",
    route: PrivateRoute,
    children: [
      {
        path: "/base/reports/units-inactives",
        name: "Units Inactives",
        element: <UnitsInactives />,
        route: PrivateRoute,
      },
      {
        path: "/base/reports/more-playeds",
        name: "More Playeds",
        element: <MorePlayeds />,
        route: PrivateRoute,
      },
      {
        path: "/base/reports/lotteries",
        name: "Result Lotteries",
        element: <ReportLottery />,
        route: PrivateRoute,
      },
      {
        path: "/base/reports/units",
        name: "Unidades Report",
        element: <UnidadesReport />,
        route: PrivateRoute,
      },
    ],
  },
];

// LOTTERY ROUTES
const systemRoutes: RoutesProps = {
  path: "/system",
  name: "system",
  header: "Navigation",
  children: [
    {
      path: "/system/limites",
      name: "Limites",
      header: "Navigation",
      element: <SystemLimits />,
      route: PrivateRoute,
    },
    {
      path: "/system/invoices",
      name: "Invoice",
      header: "Navigation",
      element: <Invoices />,
      route: PrivateRoute,
    },
  ],
};

// LOTTERY ROUTES
const lotteryRoutes: RoutesProps = {
  path: "/lottery",
  name: "Lotteries",
  header: "Navigation",
  children: [
    {
      path: "/lotteries",
      name: "Listagem",
      element: <Lotteries />,
      route: PrivateRoute,
    },
    {
      path: "/lotteries/new",
      name: "Adicionar Novo",
      element: <LotteryCreate />,
      route: PrivateRoute,
    },
    {
      path: "/lotteries/update/:id",
      name: "Atualizar",
      element: <LotteryCreate />,
      route: PrivateRoute,
    },
  ],
};

// LOTTERY CATEGORY ROUTES
const lotteryCategoryRoutes: RoutesProps = {
  path: "/lottery-category",
  name: "Lotteries Categories",
  header: "Navigation",
  children: [
    {
      path: "/lottery-categories",
      name: "Listagem",
      element: <LotteryCategories />,
      route: PrivateRoute,
    },
    {
      path: "/lottery-categories/new",
      name: "Adicionar Novo",
      element: <LotteryCategoryCreate />,
      route: PrivateRoute,
    },
    {
      path: "/lottery-categories/update/:id",
      name: "Atualizar",
      element: <LotteryCategoryCreate />,
      route: PrivateRoute,
    },
  ],
};

// EXTRACTION ROUTES
const extractionRoutes: RoutesProps = {
  path: "/extraction",
  name: "Extractions",
  header: "Navigation",
  children: [
    {
      path: "/extractions",
      name: "Listagem",
      element: <Extractions />,
      route: PrivateRoute,
    },
    {
      path: "/extractions/new",
      name: "Adicionar Novo",
      element: <ExtractionCreate />,
      route: PrivateRoute,
    },
  ],
};

// EXTRACTION ROUTES
const supportRoutes: RoutesProps = {
  path: "/support",
  name: "Support",
  header: "Navigation",
  element: <Support />,
  route: PrivateRoute,
};

// USERS ROUTES
const userRoutes: RoutesProps = {
  path: "/user",
  name: "Users",
  header: "Navigation",
  children: [
    {
      path: "/users",
      name: "Listagem",
      element: <Users />,
      route: PrivateRoute,
    },
    {
      path: "/users/new",
      name: "Adicionar Novo",
      element: <UserCreate />,
      route: PrivateRoute,
    },
    {
      path: "/users/update/:id",
      name: "Atualizar",
      element: <UserCreate />,
      route: PrivateRoute,
    },
  ],
};

// AUTH ROUTES
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    element: <Confirm />,
    route: Route,
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route,
  },
  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    element: <LockScreen />,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  },
];

// FLATTEN THE LIST OF ALL NESTED ROUTES
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// ROUTES
const publicRoutes = [...authRoutes];
const authProtectedRoutes = [
  homeRoutes,
  dashboardRoutes,
  systemRoutes,
  userRoutes,
  baseRoutes,
  lotteryRoutes,
  lotteryCategoryRoutes,
  extractionRoutes,
  supportRoutes,
  ...bancaRoutes,
];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
