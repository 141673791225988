import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import { Badge, Button, Icon, Text, useToast } from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import { FaPlus } from "react-icons/fa";

/**
 * HELPERS
 */
const api = new APICore();
const configs = {
  title: "Promoções",
  path: "/base/promotions/",
  create: "/base/promotions/new/",
  update: "/base/promotions/update/",
  apiBase: "/bancas/promotions/",
};

/**
 * TABLE DATE COLUMN
 */
const DateColumn = ({ ...props }: any) => {
  return (
    <React.Fragment>
      {new Date(props?.value).toLocaleDateString("pt-BR")}
    </React.Fragment>
  );
};

/**
 * TABLE ACTIONS
 */
const ActionColumn = ({ ...props }: any) => {
  const object = props?.row?.values;
  const dispatch = useDispatch<AppDispatch>();

  /**
   * CUSTOM HOOKS
   */
  const { publish } = useEvent();
  const toast = useToast();
  /**
   * HANDLERS
   */

  const handleClickDelete = async (id: string) => {
    if (window.confirm("Deseja realmente excluir este registro?") === false)
      return;
    dispatch(toggleLoader(true));
    await api
      .delete(configs.apiBase + id)
      ?.then((response) => {
        publish("deleteItem", null);
        // Exibe um Toast de sucesso
        toast({
          title: "Registro excluído.",
          description: "O registro foi excluído com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao excluir o registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  return (
    <React.Fragment>
      <Link
        to="#"
        className="action-icon"
        onClick={() => handleClickDelete(object?.idPromotion)}
      >
        {" "}
        <i className="mdi mdi-delete" style={{ color: "#c0392b" }}></i>
      </Link>
    </React.Fragment>
  );
};

/**
 * COMPONENT
 */
const Promotions = () => {
  /**
   * HOOKS
   */
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const toast = useToast();
  const [regions, setRegions] = useState<any[]>([]);
  const [idPromotion, setIdPromotion] = useState(null);
  const dispatch = useDispatch<AppDispatch>();

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();

  /**
   * LIFE CYCLE HELPERS
   */

  const handleCheckboxChange = async (
    idPromotion: number,
    executionAt: string,
    day: string,
    newValue: boolean
  ) => {
    const newExecution = JSON.parse(executionAt || "[]");

    if (newValue) {
      newExecution.push(day);
    } else {
      const index = newExecution.indexOf(day);
      if (index > -1) {
        newExecution.splice(index, 1);
      }
    }
    dispatch(toggleLoader(true));
    await api
      .update(configs.apiBase + idPromotion, {
        executionAt: JSON.stringify(newExecution),
      })
      .then((response) => {
        // Exibe um Toast de sucesso
        setData(response?.data ?? []);
        toast({
          title: "Registro atualizado.",
          description: "O registro foi atualizado com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Handle the error
        toast({
          title: "Erro ao buscar registros",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  const handlePageLoading = useCallback(async () => {
    dispatch(toggleLoader(true));
    await api
      .get(configs.apiBase, null)
      ?.then((response) => {
        setData(response?.data ?? []);
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao buscar registros",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });

    await api
      .get("bancas/unit-regions", null)
      .then((response) => {
        const allRegionsOption = { idRegion: "", name: "Todas as regiões" };
        const regionsData = response?.data ?? [];
        setRegions([allRegionsOption, ...regionsData]);
      })
      .catch((error: any) => {
        toast({
          title: "Não foi possível realizar a busca de regiões",
          description: error,
          status: "error",
          duration: 5000,
        });
      });
    dispatch(toggleLoader(false));
  }, [setData, setRegions]);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  /**
   * ACTIONS
   */
  const handleAdd = useCallback(() => {
    navigate(configs.create);
  }, [navigate]);

  const columns = [
    {
      Header: "ID",
      accessor: "idPromotion",
      sort: true,
      classes: "fw-semibold",
    },
    {
      Header: "Descrição",
      accessor: "title",
      sort: true,
      classes: "fw-semibold",
      Cell: ({ row }: { row: any }) => {
        const percent = () => {
          if (row.original.bonusType === 0) {
            return "R$ " + formatCurrency(row.original.bonusValue);
          } else if (row.original.bonusType === 1) {
            return `${row.original.bonusValue}%`;
          }
          return row.original.bonusValue;
        };

        return (
          <>
            <Text my={0}>{row.original.title}</Text>
            {row.original.exclusiveBonus ? (
              <>
                <Badge colorScheme="orange">EXCLUSIVO</Badge>
                <br />
              </>
            ) : null}

            {row.original.bonusType === 0 ? (
              <Badge colorScheme="red">VALOR</Badge>
            ) : (
              <Badge colorScheme="blue">PERCENTUAL</Badge>
            )}
            <br />
            <Badge colorScheme="blue">{percent()}</Badge>
          </>
        );
      },
    },
    {
      Header: "Mín.",
      accessor: "minRechargeValue",
      sort: false,
      Cell: ({ value }) => (value ? "R$ " + formatCurrency(value) : ""),
    },
    {
      Header: "Máx.",
      accessor: "maxRechargeValue",
      sort: false,
      Cell: ({ value }) => (value ? "R$ " + formatCurrency(value) : ""),
    },
    {
      Header: "Região",
      accessor: "idRegion",
      sort: false,
      Cell: ({ value }) => {
        // Encontre a região correspondente ao idRegion na matriz de regiões
        const region = regions.find((region) => region.idRegion === value);
        // Se a região for encontrada, retorne o nome da região. Caso contrário, retorne uma string vazia ou algum valor padrão.
        return region ? region.name : "Todas";
      },
    },
    {
      Header: "Recargas",
      accessor: "rechargeType",
      sort: false,
      Cell: ({ value }) => {
        switch (value) {
          case 0:
            return "Todas";
          case 1:
            return "1ª do dia";
          case 2:
            return "1ª da unidade";
          default:
            return null;
        }
      },
    },
    {
      id: "seg",
      Header: "Seg",
      accessor: "executionAt",
      sort: false,
      Cell: ({ value, row }) => {
        const dayCheck = "monday";
        return (
          <input
            type="checkbox"
            checked={JSON.parse(value).find((day: string) => day === dayCheck)}
            onChange={(e) =>
              handleCheckboxChange(
                row.values.idPromotion,
                row.values.seg,
                dayCheck,
                e.target.checked
              )
            }
          />
        );
      },
    },
    {
      id: "ter",
      Header: "Ter",
      accessor: "executionAt",
      sort: false,
      Cell: ({ value, row }) => {
        const dayCheck = "tuesday";
        return (
          <input
            type="checkbox"
            checked={JSON.parse(value).find((day: string) => day === dayCheck)}
            onChange={(e) =>
              handleCheckboxChange(
                row.values.idPromotion,
                row.values.seg,
                dayCheck,
                e.target.checked
              )
            }
          />
        );
      },
    },
    {
      id: "qua",
      Header: "Qua",
      accessor: "executionAt",
      sort: false,
      Cell: ({ value, row }) => {
        const dayCheck = "wednesday";
        return (
          <input
            type="checkbox"
            checked={JSON.parse(value).find((day: string) => day === dayCheck)}
            onChange={(e) =>
              handleCheckboxChange(
                row.values.idPromotion,
                row.values.seg,
                dayCheck,
                e.target.checked
              )
            }
          />
        );
      },
    },
    {
      id: "qui",
      Header: "Qui",
      accessor: "executionAt",
      sort: false,
      Cell: ({ value, row }) => {
        const dayCheck = "thursday";
        return (
          <input
            type="checkbox"
            checked={JSON.parse(value).find((day: string) => day === dayCheck)}
            onChange={(e) =>
              handleCheckboxChange(
                row.values.idPromotion,
                row.values.seg,
                dayCheck,
                e.target.checked
              )
            }
          />
        );
      },
    },
    {
      id: "sex",
      Header: "Sex",
      accessor: "executionAt",
      sort: false,
      Cell: ({ value, row }) => {
        const dayCheck = "friday";
        return (
          <input
            type="checkbox"
            checked={JSON.parse(value).find((day: string) => day === dayCheck)}
            onChange={(e) =>
              handleCheckboxChange(
                row.values.idPromotion,
                row.values.seg,
                dayCheck,
                e.target.checked
              )
            }
          />
        );
      },
    },
    {
      id: "sab",
      Header: "Sab",
      accessor: "executionAt",
      sort: false,
      Cell: ({ value, row }) => {
        const dayCheck = "saturday";
        return (
          <input
            type="checkbox"
            checked={JSON.parse(value).find((day: string) => day === dayCheck)}
            onChange={(e) =>
              handleCheckboxChange(
                row.values.idPromotion,
                row.values.seg,
                dayCheck,
                e.target.checked
              )
            }
          />
        );
      },
    },
    {
      id: "dom",
      Header: "Dom",
      accessor: "executionAt",
      sort: false,
      Cell: ({ value, row }) => {
        const dayCheck = "sunday";
        return (
          <input
            type="checkbox"
            checked={JSON.parse(value).find((day: string) => day === dayCheck)}
            onChange={(e) =>
              handleCheckboxChange(
                row.values.idPromotion,
                row.values.seg,
                dayCheck,
                e.target.checked
              )
            }
          />
        );
      },
    },
    {
      Header: "Ações",
      accessor: "actions",
      sort: false,
      classes: "table-action",
      Cell: ActionColumn,
    },
  ];

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row className="mb-4">
                <Col sm={4}>
                  <Button
                    mr={2}
                    colorScheme="yellow"
                    leftIcon={<Icon as={FaPlus} />}
                    onClick={handleAdd}
                  >
                    Adicionar Novo
                  </Button>
                </Col>
              </Row>

              <Table
                isSearchable={true}
                columns={columns}
                data={data}
                pageSize={12}
                isSortable={true}
                pagination={true}
                tableClass="table-nowrap mt-3 table-hover table-striped"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Promotions;
