import { Row, Col, Card, Button } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Button as ChakraButton,
  Badge,
  ButtonGroup,
  Input,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Select,
  Text,
  useToast,
  Box,
  ModalFooter,
  Spacer,
  Td,
  Tr,
  Tbody,
  TableContainer,
  Thead,
  Th,
  Flex,
  ModalBody,
  ModalCloseButton,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  Table as ChakraTable,
  useDisclosure,
  position,
  Heading,
  Divider,
  Tooltip,
  Image,
} from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import { format, parseISO } from "date-fns";
import { CheckIcon, RepeatIcon } from "@chakra-ui/icons";
import StatisticsWidget from "../../../components/StatisticsWidget";
import { sumTotalValue } from "../../../helpers/sum";
import StatisticsChartWidget from "../../../components/StatisticsChartWidget";

/**
 * HELPERS
 */

const api = new APICore();
const configs = {
  title: "Premios",
  path: "/base/awards/",
  update: "/base/awards/update/",
  apiBase: "/bancas/awards/",
};

/**
 * TABLE DATE COLUMN
 */
const DateColumn = ({ ...props }: any) => {
  return (
    <React.Fragment>
      {props?.value && format(new Date(props?.value), "dd/MM/yyyy HH:mm")}
    </React.Fragment>
  );
};

/**
 * TABLE ACTIONS
 */
const ActionColumn = ({ ...props }: any) => {
  const { userData } = useSelector((state: RootState) => ({
    userData: state?.Auth?.user?.userData,
  }));
  const object = props?.row?.original;
  const idUnit = props?.row?.original?.id;
  const all = props?.rows;

  const filteredRows =
    all
      ?.map((row: { original: any }) => row.original)
      .filter((original: { id: any }) => original.id === idUnit) || [];

  const sumAllAwards = filteredRows.reduce(
    (sum: number, item: { award: number }) => sum + (item.award || 0),
    0
  );

  const dispatch = useDispatch<AppDispatch>();

  /**
   * CUSTOM HOOKS
   */
  const { publish } = useEvent();
  const toast = useToast();
  const status = object?.status;
  const id = object?.id;

  const listStatus = [
    {
      name: "PAGA",
      menu: "Pagar",
      value: "paid",
      bg: "#00a65a",
      icon: <CheckIcon />,
    },
  ];

  const selected = listStatus.find((status) => status.value === object?.status);

  const {
    isOpen: isOpenPrint,
    onOpen: onOpenPrint,
    onClose: onClosePrint,
  } = useDisclosure();

  return (
    <>
      <Modal
        isOpen={isOpenPrint}
        onClose={onClosePrint}
        size={{ base: "full", md: "lg" }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            {/*  */}
            <Box py={5} bg={"white"}>
              <Flex justifyContent={"center"}>
                <Text
                  my={0}
                  fontSize={"xl"}
                  fontWeight={"bold"}
                  textTransform={"uppercase"}
                >
                  {/* {configs.title} */}
                  {userData?.base?.title || ""}
                </Text>
              </Flex>
              <Flex mt={3}>
                <Flex>
                  <Text my={0} fontWeight={"bold"} fontSize={"xs"}>
                    VENDEDOR
                  </Text>
                </Flex>
                <Spacer p={0} />
                <Flex>
                  <Text my={0}>{object?.id}</Text>
                </Flex>
              </Flex>
              <Flex>
                <Flex>
                  <Text my={0}> {format(new Date(), "dd/MM/y")}</Text>
                </Flex>
                <Spacer />
                <Flex>
                  <Text my={0}> {format(new Date(), "HH:mm:ss")}</Text>
                </Flex>
              </Flex>
              <Divider my={2} />

              <Flex>
                <Flex>
                  <Text my={0}>PREMIADAS</Text>
                </Flex>
                <Spacer />
                <Flex>
                  <Text my={0}>
                    {format(
                      parseISO(
                        object?.createdAt || format(new Date(), "yyyy-MM-dd")
                      ),
                      "dd/MM/yyyy"
                    )}
                  </Text>
                </Flex>
              </Flex>
              <Divider my={2} />

              {filteredRows.map((item) => (
                <>
                  <Box
                    mt={3}
                    border={"1px solid #cccccc"}
                    p={4}
                    borderRadius={12}
                  >
                    <Flex>
                      <Flex>
                        <Text my={0} fontWeight={"bold"}>
                          PULE #{item?.idPule}
                        </Text>
                      </Flex>
                      <Spacer />
                      <Flex>
                        <Text my={0}>
                          {item?.loteriaTitle} #{item?.idPuleLottery}
                        </Text>
                      </Flex>
                    </Flex>
                    <Divider my={1} />

                    <>
                      <Flex>
                        <Flex>
                          <Text my={0} fontWeight={"500"}>
                            {item?.modalidade} - {item?.numero}
                          </Text>
                        </Flex>
                        <Spacer />
                        <Flex>
                          <Text my={0} whiteSpace="nowrap">
                            {item?.colocacao}
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex>
                        <Flex>
                          <Text my={0}>
                            R$ {formatCurrency(item?.amount || 0)}
                          </Text>
                        </Flex>
                        <Spacer />
                        <Flex>
                          <Text my={0} fontWeight={"500"}>
                            R$ {formatCurrency(item?.award || 0)}
                          </Text>
                        </Flex>
                      </Flex>
                    </>
                  </Box>
                </>
              ))}

              <>
                <Divider my={5} />
                <Flex>
                  <Flex>
                    <Text my={0}>TOTAL P.</Text>
                  </Flex>
                  <Spacer />
                  <Flex>
                    <Text my={0} fontWeight={"500"}>
                      R$ {formatCurrency(sumAllAwards || 0)}
                    </Text>
                  </Flex>
                </Flex>
              </>
            </Box>
            {/*  */}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Flex gap={2} justifyContent={"center"} alignItems={"center"}>
        <Badge
          bgColor={selected?.bg}
          color="white"
          width={"100%"}
          textAlign={"center"}
        >
          {selected?.name}
        </Badge>
        <Spacer />
        <Box cursor={"pointer"} onClick={onOpenPrint}>
          <i
            className="mdi mdi-file"
            style={{ color: "blue", fontSize: 22 }}
          ></i>
        </Box>
      </Flex>
    </>
  );
};

/**
 * COMPONENT
 */
const Premios = () => {
  /**
   * HOOKS
   */
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState([]);
  const toast = useToast();
  const [dataSelect, setDataSelect] = useState<any>(null);
  const [formUnit, setFormUnit] = useState<string>("");
  const [formStartDate, setFormStartDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [formEndDate, setFormEndDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [selectedPule, setSelectedPule] = useState<any>({});
  const [modalities, setModalities] = useState([]);
  const [formStatus, setFormStatus] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [chartConfig, setChartConfig] = useState({
    apxOptions: {},
    apxSeries: [],
    type: "bar",
    height: 350,
  });

  const openModal = async (row: any) => {
    const idPuleLottery = row?.original?.idPuleLottery;
    const idPule = row?.original?.idPule;
    dispatch(toggleLoader(true));
    try {
      await api
        .get(`/bancas/pules/${idPule}?idPuleLottery=${idPuleLottery}`, null)
        .then((response) => {
          setSelectedPule(response.data ?? []);
        });
      onOpen();
    } catch (e) {
      toast({
        title: "Erro ao buscar dados da pule.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    dispatch(toggleLoader(false));
  };

  const getNameOfModality = (idModality: number) => {
    if (idModality === 0) {
      return "Todas";
    }

    const find = modalities.find(
      (modality: any) => modality.idModality === idModality
    )?.code;

    return find || "Não encontrado";
  };

  const getFormattedDate = (date: any, isTime: boolean) => {
    if (isTime) {
      return (
        new Date(date).toLocaleDateString("pt-BR") +
        " " +
        new Date(date).toLocaleTimeString("pt-BR")
      );
    }
    return new Date(date).toLocaleDateString("pt-BR");
  };

  const columns = [
    {
      Header: "Data de Criação",
      accessor: "createdAt",
      sort: true,
      Cell: DateColumn,
    },
    {
      Header: "ID",
      accessor: "id",
      sort: true,
    },
    {
      Header: "Nome Completo",
      accessor: "fullname",
      sort: true,
      Cell: ({ row, value }: { value: any; row: any }) => {
        const values = row?.original;
        const now = new Date();
        const unitCreatedAt = new Date(values.unitCreatedAt);
        const diff = Math.abs(now.getTime() - unitCreatedAt.getTime());
        const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));

        return (
          <a
            href={`https://wa.me/+55${values?.whatsapp.replace(
              /\D/g,
              ""
            )}?text=${encodeURI(
              `Olá ${values.fullname.trim().split(" ")[0]} tudo bem? \r\n `
            )}`}
            target="_blank"
            rel="noreferrer"
          >
            <Box>
              <b>{value}</b> <br />
              {values?.identifier} <br />
              {format(new Date(values?.unitCreatedAt), "dd/MM/yyyy HH:mm")}
              <br />
              {diffDays <= 10 && (
                <Badge colorScheme="red">CADASTRO RECENTE</Badge>
              )}
            </Box>
          </a>
        );
      },
    },
    {
      Header: "ID Pule",
      accessor: "idPule",
      sort: true,
      Cell: ({ row, value }: { value: any; row: any }) => (
        <Box onClick={() => openModal(row)} cursor={"pointer"} color="#3081D0">
          {value}
        </Box>
      ),
    },
    {
      Header: "Loteria",
      accessor: "loteria",
      sort: true,
    },
    {
      Header: "Modalidade",
      accessor: "modalidade",
      sort: true,
    },
    {
      Header: "Número",
      accessor: "numero",
      sort: true,
      Cell: ({ value }) => {
        const formattedAmount = value
          .split("-")
          .map((item: any, index: number) => {
            return (
              <Badge key={index} colorScheme="blue" mr={1} mb={1}>
                {item}
              </Badge>
            );
          });
        return (
          <Box display="flex" flexWrap="wrap">
            {formattedAmount}
          </Box>
        );
      },
    },
    {
      Header: "Colocação",
      accessor: "colocacao",
      sort: true,
    },
    {
      Header: "Aposta",
      accessor: "amount",
      sort: true,
      Cell: ({ value }) => {
        const formattedAmount = formatCurrency(value);
        return <span>R$ {formattedAmount}</span>;
      },
    },
    {
      Header: "Prêmio",
      accessor: "award",
      sort: true,
      Cell: ({ value }) => {
        const formattedAmount = formatCurrency(value);
        return <span>R$ {formattedAmount}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      sort: true,
      classes: "fw-semibold",
      Cell: ActionColumn,
    },
  ];

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();

  /**
   * LIFE CYCLE HELPERS
   */
  const fetchData = async () => {
    dispatch(toggleLoader(true));
    try {
      let dates = {
        startDate: formStartDate,
        endDate: formStartDate,
      };
      let payload = {
        ...dates,
        status: formStatus,
        unit: formUnit,
      };

      await api.create(configs.apiBase, payload).then((response) => {
        setData(response.data ?? []);
        setModalities(response.data.modalities ?? []);
      });
      await api.get("/bancas/bff/select-data", null).then((response) => {
        setDataSelect(response.data ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar dados de registro.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    dispatch(toggleLoader(false));
  };

  const handlePageLoading = useCallback(async () => {
    await fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  /**
   * ACTIONS
   */

  const preparePayload = (type: string) => {
    let dates = {
      startDate: formStartDate,
      endDate: formEndDate,
    };
    let payload = {
      ...dates,
      status: formStatus,
      unit: formUnit,
    };
    const hoje = format(new Date(), "yyyy-MM-dd");
    const decreaseDate = (qtd: number) => {
      const date = new Date();
      date.setDate(date.getDate() - qtd);
      return format(date, "yyyy-MM-dd");
    };
    if (type === "hoje") {
      payload = {
        ...payload,
        startDate: hoje,
        endDate: hoje,
      };
    } else if (type === "ontem") {
      payload = {
        ...payload,
        startDate: decreaseDate(1),
        endDate: decreaseDate(1),
      };
    }
    setFormStartDate(payload.startDate);
    setFormEndDate(payload.endDate);
    return payload;
  };
  const handleSearch = async (type: string) => {
    const payload = preparePayload(type);
    dispatch(toggleLoader(true));
    try {
      await api.create(configs.apiBase, payload).then((response) => {
        setData(response.data ?? []);
        setModalities(response.data.modalities ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar dados de registro.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    dispatch(toggleLoader(false));
  };

  const configureChart = (premios: any[]) => {
    if (!premios || premios.length === 0) return;

    // Agrupa os prêmios por modalidade e conta a quantidade de ocorrências
    const modalidadeCounts = premios.reduce(
      (acc: { [x: string]: any }, premio: { modalidade: any }) => {
        const modalidade = premio.modalidade;
        acc[modalidade] = (acc[modalidade] || 0) + 1;
        return acc;
      },
      {}
    );

    // Calcula a soma total de "award" para cada modalidade
    const modalidadeAwards = premios.reduce(
      (acc: { [x: string]: any }, premio: { modalidade: any; award: any }) => {
        const modalidade = premio.modalidade;
        acc[modalidade] = (acc[modalidade] || 0) + premio.award;
        return acc;
      },
      {}
    );

    // Converte o objeto para um array de [modalidade, quantidade]
    const modalidadeArray = Object.entries(modalidadeCounts);

    // Ordena o array em ordem decrescente com base na quantidade
    const sortedModalidades = modalidadeArray.sort((a, b) => b[1] - a[1]);

    // Limita aos 10 primeiros após a ordenação
    const top10Modalidades = sortedModalidades.slice(0, 10);

    // Configura as séries de dados e categorias para o gráfico
    const categories = top10Modalidades.map(([modalidade]) => modalidade);
    const series = top10Modalidades.map(
      ([modalidade]) => modalidadeCounts[modalidade]
    );

    // Array com a soma total de "award" para cada modalidade
    const awardsSums = top10Modalidades.map(
      ([modalidade]) => modalidadeAwards[modalidade]
    );

    // Paleta de cores para as 10 modalidades
    const colors = [
      "#727cf5",
      "#0acf97",
      "#fa5c7c",
      "#ffbc00",
      "#39afd1",
      "#6c757d",
      "#0000CD",
      "#8B4513",
      "#FF00FF",
      "#ff9f43",
    ];

    // Configura o gráfico
    const options = {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          distributed: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: number) {
          return Math.floor(val);
        },
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
        offsetY: -25,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        labels: {
          formatter: function (val: number) {
            return Math.floor(val);
          },
        },
      },
      colors: colors,
      title: {
        text: "Top 10 Quantidade de Prêmios por Modalidade",
        align: "center",
      },
      tooltip: {
        y: {
          formatter: function (value: any, { dataPointIndex }: any) {
            // Formata o valor como BRL
            return awardsSums[dataPointIndex].toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        },
      },
    };

    // Atualiza o estado com a configuração do gráfico
    setChartConfig({
      apxOptions: options,
      apxSeries: [{ name: "Valor premiado", data: series }],
      type: "bar",
      height: 350,
    });
  };

  useEffect(() => {
    if (data?.premios) {
      configureChart(data?.premios);
    }
  }, [data]);

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs?.title,
            path: configs?.path,
            active: true,
          },
        ]}
        title={configs?.title}
      />

      <Row>
        <Col>
          <Row>
            <Col>
              <Row>
                {/* Coluna que contém os dois StatisticsWidget */}
                <Col md={6}>
                  <Row>
                    <Col md={12}>
                      <StatisticsWidget
                        variant="danger"
                        counterOptions={{
                          prefix: "R$ ",
                          preserveValue: true,
                          decimal: ",",
                          separator: ".",
                        }}
                        description="Valor total"
                        stats={sumTotalValue(data?.premios, "award")}
                        icon="fe-award"
                      />
                    </Col>
                    <Col md={12}>
                      <StatisticsWidget
                        variant="warning"
                        counterOptions={{}}
                        description="Quantidade"
                        stats={data?.premios?.length || 0}
                        icon="fe-bar-chart-line"
                      />
                    </Col>
                  </Row>
                </Col>
                {/* Coluna que contém o StatisticsChartWidget sozinho */}
                <Col md={6}>
                  <StatisticsChartWidget
                    icon="mdi mdi-chart-bar"
                    variant="primary"
                    stats={String(data?.premios?.length || 0)}
                    description="Total de Prêmios"
                    chartConfig={chartConfig}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Card>
            <Card.Body>
              <Flex direction={["column", "row"]} wrap="wrap" gap={2} my={2}>
                <ChakraButton
                  colorScheme="orange"
                  onClick={() => handleSearch("ontem")}
                >
                  Ontem
                </ChakraButton>
                <ChakraButton
                  colorScheme="yellow"
                  onClick={() => handleSearch("hoje")}
                >
                  Hoje
                </ChakraButton>
              </Flex>
              <Row>
                <Col md={4}>
                  <Row>
                    <Col md={12}>
                      <Text fontWeight={"bold"} my={0} mt={1}>
                        DATA:
                      </Text>
                      <Input
                        placeholder="Data:"
                        size="md"
                        type="date"
                        value={formStartDate}
                        onChange={(e) => setFormStartDate(e.target.value)}
                      />
                    </Col>
                    <Col md={12}>
                      <Text fontWeight={"bold"} my={0} mt={1}>
                        UNIDADE:
                      </Text>
                      <Select
                        placeholder="Todas as unidades"
                        value={formUnit}
                        onChange={(e) => setFormUnit(e.target.value)}
                      >
                        <optgroup label={"Regiões"}>
                          {dataSelect?.regions.map((item: any) => (
                            <option value={"R:" + item.idRegion}>
                              {item.name}
                            </option>
                          ))}
                        </optgroup>
                        <optgroup label={"Cobradores"}>
                          {dataSelect?.collectors.map((item: any) => (
                            <option value={"P:" + item.idUnit}>
                              {item.fullName}
                            </option>
                          ))}
                        </optgroup>
                      </Select>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col md={12}>
                      <Button
                        variant="primary"
                        className="waves-effect waves-light"
                        style={{ backgroundColor: "#4c695c" }}
                        onClick={() => handleSearch("default")}
                      >
                        <i className="fas fa-search me-1"></i> Buscar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Table
                isSearchable={true}
                columns={columns}
                data={data.premios ?? []}
                pageSize={12}
                isSortable={true}
                pagination={true}
                tableClass="table-nowrap mt-3 table-hover table-striped"
                tfoot={undefined}
              />
            </Card.Body>
          </Card>
        </Col>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent maxW={"900px"}>
            <ModalHeader>
              Detalhes da Pule #{selectedPule?.idPule}-
              {selectedPule?.lotteries?.length > 0 &&
                selectedPule?.lotteries[0]?.idPuleLottery}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box border="1px solid #ccc" p={2} borderRadius={4}>
                <Flex>
                  <Text fontWeight={"bold"}>Unidade: </Text>

                  <Text pl={2}>
                    {selectedPule?.unit?.fullName?.toUpperCase()}
                  </Text>
                  <Spacer></Spacer>
                  <Text fontWeight={"bold"}>Valendo: </Text>
                  <Text pl={2}>
                    {getFormattedDate(selectedPule?.executionAt, false)}
                  </Text>
                </Flex>

                <Flex>
                  <Text fontWeight={"bold"}>Data/Hora: </Text>

                  <Text pl={2}>
                    {getFormattedDate(selectedPule?.createdAt, true)}
                  </Text>

                  <Spacer></Spacer>
                  <Text fontWeight={"bold"}>Loteria:</Text>
                  <Text pl={2}>
                    {selectedPule?.lotteries?.length > 0 &&
                      selectedPule?.lotteries[0]?.lottery?.title}
                  </Text>
                </Flex>

                <Flex>
                  <Text fontWeight={"bold"}>Total Pule: </Text>
                  <Text pl={2}>
                    R${" "}
                    {selectedPule?.price_unit
                      ? formatCurrency(selectedPule.price_unit)
                      : "0.00"}
                  </Text>
                  <Spacer></Spacer>
                  <Text fontWeight={"bold"}>Cotação:</Text>
                  <Text pl={2}>{selectedPule?.paymentTable?.name}</Text>
                </Flex>

                <Flex>
                  <Text fontWeight={"bold"}>Comissão: </Text>
                  <Text pl={2}>
                    {JSON.parse(
                      selectedPule?.unit?.modalityComissions || "[]"
                    )?.map((comission: any) => {
                      return (
                        <Badge mr={1} border={"1px solid #444"}>
                          {" "}
                          {getNameOfModality(comission?.idModality)} -{" "}
                          {comission?.value}%
                        </Badge>
                      );
                    })}
                  </Text>
                </Flex>
              </Box>

              <TableContainer mt={2} border="1px solid #ccc">
                <ChakraTable size="sm" mt={2}>
                  <Thead>
                    <Tr>
                      <Th>Modalidade</Th>
                      <Th>Palpite</Th>
                      <Th>Posição</Th>
                      <Th isNumeric>Valor</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {selectedPule?.predictions?.map((item: any) =>
                      item?.values?.map((value: any) => {
                        return (
                          <Tr>
                            <Td>{item.modality.name}</Td>
                            <Td>{value.value}</Td>
                            <Td>{item.colocation}</Td>
                            <Td isNumeric>R$ {formatCurrency(value.price)}</Td>
                          </Tr>
                        );
                      })
                    )}
                  </Tbody>
                </ChakraTable>
              </TableContainer>
            </ModalBody>

            <ModalFooter gap={2}>
              {/* <Button variant="danger">Cancelar Pule</Button> */}
              <Spacer></Spacer>
              {/* <Button variant="info">Recibo</Button> */}
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Fechar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Row>
    </React.Fragment>
  );
};

export default Premios;
