import React, { useState } from "react";
import { Button as ChakraButton, Icon } from "@chakra-ui/react";
import EditRaffleModal from "./EditRaffleModal"; // Ajuste o caminho do import conforme necessário
import { Button, Col, Row } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { getActionsPermission } from "../../../helpers/api/auth";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const CreateRaffleButton = () => {
  const { permission } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));
  const actions = getActionsPermission({ permission, role: "Unidades" });

  // Estado para controlar a visibilidade da modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRaffle, setSelectedRaffle] = useState(null); // Pode ser nulo para criação de nova rifa
  const [objectDisabled, setObjectDisabled] = useState({});

  const openModal = () => {
    // Se a rifa for passada, é uma edição, caso contrário, é criação
    setSelectedRaffle(null);
    setObjectDisabled({}); // Se for criar, passar um objeto vazio
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedRaffle(null); // Resetar a rifa selecionada
    setObjectDisabled({});
  };

  return (
    <>
      <Row className="mb-4">
        <Col sm={4}>
          {actions.canCreate && (
            <ChakraButton
              mr={2}
              colorScheme="yellow"
              leftIcon={<Icon as={FaPlus} />}
              onClick={openModal}
            >
              Adicionar Novo
            </ChakraButton>
          )}
        </Col>
      </Row>

      {/* Modal para criar ou editar a rifa */}
      <EditRaffleModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        selectedRaffle={selectedRaffle}
        objectDisabled={objectDisabled}
        setObjectDisabled={setObjectDisabled}
      />
    </>
  );
};

export default CreateRaffleButton;
