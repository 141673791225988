import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Wizard, Steps, Step } from "react-albus";
import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Card, Button, ProgressBar } from "react-bootstrap";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import { FormInput } from "../../../components";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import { useRegisterForm } from "../../../hooks";
import { Box, Grid, Text, useToast } from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import FormSelectInput from "../../../components/FormSelect";
import {
  formatCurrency,
  parseCurrency,
} from "@brazilian-utils/brazilian-utils";

/**
 * HELPERS
 */
const api = new APICore();
const configs = {
  title: "Promoções",
  path: "/base/promotions/",
  create: "/base/promotions/new/",
  update: "/base/promotions/update/",
  apiBase: "/bancas/promotions/",
};

/**
 * INTERFACES
 */
interface FirstStepWizard {
  title: string;
  minRechargeValue: string;
  maxRechargeValue: string;
  bonusValue: string;
  idRegion: string;
  bonusType: string;
  rechargeType: string;
  exclusiveBonus: string;
  executionAt: string[];
}

/**
 * FORM VALIDATIONS
 */
const resolver = yupResolver(
  yup.object().shape({
    title: yup.string().required("Por favor, insira uma descrição"),
    minRechargeValue: yup
      .string()
      .required("Por favor, insira um valor mínimo de recarga"),
    maxRechargeValue: yup
      .string()
      .required("Por favor, insira um valor máximo de recarga"),
    bonusValue: yup.string().required("Por favor, insira um valor de bônus"),
    idRegion: yup.string(),
    bonusType: yup.string().required("Por favor, selecione um tipo de bônus"),
    rechargeType: yup
      .string()
      .required("Por favor, selecione um tipo de recarga"),
    exclusiveBonus: yup
      .string()
      .required("Por favor, confira se o bônus será exclusivo."),
  })
);

const WizardForm = ({ idUpdate }: any) => {
  const dispatch = useDispatch<AppDispatch>();

  /**
   * HOOKS
   */
  const navigate = useNavigate();
  const firstWizard = useRegisterForm<FirstStepWizard>({ resolver });
  const toast = useToast();
  const [regions, setRegions] = useState<any[]>([]);

  const handleCheckboxChange = (day: keyof typeof days, newValue: boolean) => {
    setDays((prevDays) => {
      const updatedDays = { ...prevDays, [day]: newValue };
      const checkedDays = Object.keys(updatedDays).filter(
        (day) => updatedDays[day]
      );
      firstWizard.setValue("executionAt", checkedDays);
      return updatedDays;
    });
  };

  const [days, setDays] = useState({
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
  });

  const mountApiData = () => {
    return {
      title: firstWizard.getValues().title,
      minRechargeValue: firstWizard.getValues().minRechargeValue,
      maxRechargeValue: firstWizard.getValues().maxRechargeValue,
      bonusValue: firstWizard.getValues().bonusValue,
      idRegion: firstWizard.getValues().idRegion,
      bonusType: firstWizard.getValues().bonusType,
      exclusiveBonus:
        firstWizard.getValues().exclusiveBonus === "true" ? true : false,
      rechargeType: firstWizard.getValues().rechargeType,
      executionAt: Object.keys(days).filter((day) => days[day]),
    };
  };

  /**
   * LIFE CYCLE HELPERS
   */
  const handlePageLoadUpdate = useCallback(async () => {
    dispatch(toggleLoader(true));
    await api
      .get(configs.apiBase + idUpdate, null)
      .then((response) => {
        const data: any = response?.data ?? {};

        firstWizard.setValue("title", data?.title);
        firstWizard.setValue("minRechargeValue", data?.minRechargeValue);
        firstWizard.setValue("maxRechargeValue", data?.maxRechargeValue);
        firstWizard.setValue("bonusValue", data?.bonusValue);
        firstWizard.setValue("idRegion", data?.idRegion);
        firstWizard.setValue("bonusType", data?.bonusType);
        firstWizard.setValue("rechargeType", data?.rechargeType);
        firstWizard.setValue("exclusiveBonus", data?.exclusiveBonus);
        firstWizard.setValue("executionAt", data?.executionAt);
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao buscar registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        dispatch(toggleLoader(false));

        navigate(configs.path);
      });
    dispatch(toggleLoader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idUpdate]);

  const handlePageLoad = useCallback(async () => {
    dispatch(toggleLoader(true));
    await api
      .get("bancas/unit-regions", null)
      .then((response) => {
        const allRegionsOption = { idRegion: "", name: "Todas as regiões" };
        const regionsData = response?.data ?? [];
        setRegions([allRegionsOption, ...regionsData]);
      })
      .catch((error: any) => {
        toast({
          title: "Não foi possível realizar a busca de regiões",
          description: error,
          status: "error",
          duration: 5000,
        });
      });
    dispatch(toggleLoader(false));
    if (idUpdate) handlePageLoadUpdate();
  }, [idUpdate, handlePageLoadUpdate]);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageLoad();
  }, [handlePageLoad]);

  /**
   * AUXILIARS
   */

  /**
   * ACTIONS
   */
  const handleSubmit = useCallback(async () => {
    if (!idUpdate) {
      return await api
        .create(configs.apiBase, mountApiData())
        .then(() => {
          dispatch(toggleLoader(false));
          navigate(configs.path);
          toast({
            title: "Registro adicionado com sucesso.",
            description: "O registro foi adicionado com sucesso.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          dispatch(toggleLoader(false));
          // Exibe um Toast de erro
          toast({
            title: "Erro ao adicionar registro.",
            description: error,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    }

    await api
      .update(configs.apiBase + idUpdate, mountApiData())
      .then(() => {
        dispatch(toggleLoader(false));
        navigate(configs.path);
        toast({
          title: "Registro atualizado com sucesso.",
          description: "O registro foi atualizado com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        dispatch(toggleLoader(false));
        toast({
          title: "Erro ao atualizar registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }, [idUpdate, mountApiData, navigate]);

  return (
    <Card>
      <Card.Body>
        <h4 className="header-title mb-3">Informações</h4>

        <Wizard
          render={({ step, steps }) => (
            <React.Fragment>
              <ProgressBar
                animated
                striped
                variant="success"
                now={((steps.indexOf(step) + 1) / steps.length) * 100}
                className="mb-3 progress-sm"
              />

              <Steps>
                <Step
                  id="infos"
                  render={({ next }) => (
                    <form onSubmit={firstWizard.handleSubmit(next)}>
                      <Grid
                        templateColumns={{
                          base: "repeat(1, 1fr)",
                          md: "repeat(2, 1fr)",
                        }}
                        gap={{
                          base: 0,
                          md: 4,
                        }}
                      >
                        <FormInput
                          key="title"
                          type="text"
                          name="title"
                          label="Descrição"
                          className="form-control"
                          containerClass={"mb-3"}
                          register={firstWizard.register}
                          control={firstWizard.control}
                          errors={firstWizard.errors}
                        />
                        <FormInput
                          key="minRechargeValue"
                          type="text"
                          name="minRechargeValue"
                          label="Valor mínimo de recarga em (R$)"
                          className="form-control"
                          containerClass={"mb-3"}
                          register={firstWizard.register}
                          control={firstWizard.control}
                          errors={firstWizard.errors}
                          onChange={(e: any) => {
                            return (e.target.value = formatCurrency(
                              parseCurrency(e.target.value)
                            ));
                          }}
                        />
                        <FormSelectInput
                          key="idRegion"
                          name="idRegion"
                          label="Selecione uma região"
                          multiple={false}
                          labelKey={"label"}
                          containerClass={"mb-3"}
                          resetField={firstWizard.resetField}
                          getValues={firstWizard.getValues}
                          setValue={firstWizard.setValue}
                          register={firstWizard.register}
                          removeFirstOption={true}
                          errors={firstWizard.errors}
                          options={regions.map((item: any) => {
                            return {
                              label: item.name,
                              value: item.idRegion,
                            };
                          })}
                        />

                        <FormInput
                          key="maxRechargeValue"
                          type="text"
                          name="maxRechargeValue"
                          label="Valor máximo de recarga em (R$)"
                          className="form-control"
                          containerClass={"mb-3"}
                          register={firstWizard.register}
                          control={firstWizard.control}
                          errors={firstWizard.errors}
                          onChange={(e: any) => {
                            return (e.target.value = formatCurrency(
                              parseCurrency(e.target.value)
                            ));
                          }}
                        />
                        <FormSelectInput
                          key="bonusType"
                          name="bonusType"
                          label="Tipo Bônus"
                          multiple={false}
                          labelKey={"label"}
                          containerClass={"mb-3"}
                          resetField={firstWizard.resetField}
                          getValues={firstWizard.getValues}
                          setValue={firstWizard.setValue}
                          register={firstWizard.register}
                          errors={firstWizard.errors}
                          options={[
                            { label: "Valor", value: "value" },
                            { label: "Percentual", value: "percent" },
                          ]}
                        />

                        <FormSelectInput
                          key="exclusiveBonus"
                          name="exclusiveBonus"
                          label="Elegível"
                          multiple={false}
                          labelKey={"label"}
                          containerClass={"mb-3"}
                          resetField={firstWizard.resetField}
                          getValues={firstWizard.getValues}
                          setValue={firstWizard.setValue}
                          register={firstWizard.register}
                          errors={firstWizard.errors}
                          options={[
                            {
                              label: "Todos os jogos",
                              value: "false",
                            },
                            // raffle to do
                            // {
                            //   label: "Apenas rifa",
                            //   value: "true",
                            // },
                          ]}
                        />

                        <FormInput
                          key="bonusValue"
                          type="text"
                          name="bonusValue"
                          label="Valor Bônus"
                          className="form-control"
                          containerClass={"mb-3"}
                          register={firstWizard.register}
                          control={firstWizard.control}
                          errors={firstWizard.errors}
                          onChange={(e: any) => {
                            return (e.target.value = formatCurrency(
                              parseCurrency(e.target.value)
                            ));
                          }}
                        />

                        <FormSelectInput
                          key="rechargeType"
                          name="rechargeType"
                          label="Quais Recargas?"
                          multiple={false}
                          labelKey={"label"}
                          containerClass={"mb-3"}
                          resetField={firstWizard.resetField}
                          getValues={firstWizard.getValues}
                          setValue={firstWizard.setValue}
                          register={firstWizard.register}
                          errors={firstWizard.errors}
                          options={[
                            {
                              label: "Todas recargas",
                              value: "all",
                            },
                            {
                              label: "Primeira do dia",
                              value: "firstOfDay",
                            },
                            {
                              label: "Primeira da unidade",
                              value: "firstOfUnit",
                            },
                          ]}
                        />
                      </Grid>

                      <Box>
                        <Text fontSize="xl" fontWeight={"bold"}>
                          Selecione os dias para Ativação:
                        </Text>
                        <Grid
                          templateColumns={{
                            base: "repeat(1, 1fr)",
                            md: "repeat(3, 1fr)",
                          }}
                          gap={{
                            base: 0,
                            md: 0,
                          }}
                        >
                          <FormInput
                            key="monday"
                            name="monday"
                            type="checkbox"
                            label="Segunda"
                            containerClass={"mb-3"}
                            register={firstWizard.register}
                            control={firstWizard.control}
                            errors={firstWizard.errors}
                            defaultChecked={true}
                            onChange={(e) =>
                              handleCheckboxChange("monday", e.target.checked)
                            }
                          />
                          <FormInput
                            key="tuesday"
                            name="tuesday"
                            type="checkbox"
                            label="Terça"
                            containerClass={"mb-3"}
                            register={firstWizard.register}
                            control={firstWizard.control}
                            errors={firstWizard.errors}
                            defaultChecked={true}
                            onChange={(e) =>
                              handleCheckboxChange("tuesday", e.target.checked)
                            }
                          />
                          <FormInput
                            key="wednesday"
                            name="wednesday"
                            type="checkbox"
                            label="Quarta"
                            containerClass={"mb-3"}
                            register={firstWizard.register}
                            control={firstWizard.control}
                            errors={firstWizard.errors}
                            defaultChecked={true}
                            onChange={(e) =>
                              handleCheckboxChange(
                                "wednesday",
                                e.target.checked
                              )
                            }
                          />
                          <FormInput
                            key="thursday"
                            name="thursday"
                            type="checkbox"
                            label="Quinta"
                            containerClass={"mb-3"}
                            register={firstWizard.register}
                            control={firstWizard.control}
                            errors={firstWizard.errors}
                            defaultChecked={true}
                            onChange={(e) =>
                              handleCheckboxChange("thursday", e.target.checked)
                            }
                          />
                          <FormInput
                            key="friday"
                            name="friday"
                            type="checkbox"
                            label="Sexta"
                            containerClass={"mb-3"}
                            register={firstWizard.register}
                            control={firstWizard.control}
                            errors={firstWizard.errors}
                            defaultChecked={true}
                            onChange={(e) =>
                              handleCheckboxChange("friday", e.target.checked)
                            }
                          />
                          <FormInput
                            key="saturday"
                            name="saturday"
                            type="checkbox"
                            label="Sábado"
                            containerClass={"mb-3"}
                            register={firstWizard.register}
                            control={firstWizard.control}
                            errors={firstWizard.errors}
                            defaultChecked={true}
                            onChange={(e) =>
                              handleCheckboxChange("saturday", e.target.checked)
                            }
                          />
                          <FormInput
                            key="sunday"
                            name="sunday"
                            type="checkbox"
                            label="Domingo"
                            containerClass={"mb-3"}
                            register={firstWizard.register}
                            control={firstWizard.control}
                            errors={firstWizard.errors}
                            defaultChecked={true}
                            onChange={(e) =>
                              handleCheckboxChange("sunday", e.target.checked)
                            }
                          />
                        </Grid>
                      </Box>

                      <ul className="list-inline wizard mb-0">
                        <li className="next list-inline-item float-end">
                          <Button type="submit" variant="success">
                            Próximo
                          </Button>
                        </li>
                      </ul>
                    </form>
                  )}
                />

                <Step
                  id="confirmation"
                  render={({ previous }) => (
                    <Row>
                      <Col sm={12}>
                        <div className="text-center">
                          <h2 className="mt-0">
                            <i className="mdi mdi-check-all"></i>
                          </h2>
                          <h3 className="mt-0">Finalizado!</h3>

                          <p className="w-75 mb-2 mx-auto">
                            Deseja realmente prosseguir com a criação?
                          </p>
                        </div>
                      </Col>

                      <Col sm={12}>
                        <ul className="list-inline wizard mb-0">
                          <li className="previous list-inline-item">
                            <Button
                              type="submit"
                              variant="info"
                              onClick={previous}
                            >
                              Anterior
                            </Button>
                          </li>

                          <li className="next list-inline-item float-end">
                            <Button
                              type="submit"
                              variant="success"
                              onClick={handleSubmit}
                            >
                              Enviar
                            </Button>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  )}
                />
              </Steps>
            </React.Fragment>
          )}
        />
      </Card.Body>
    </Card>
  );
};

const PromotionsCreate = () => {
  const { id } = useParams();
  const [idUpdate, setIdUpdate] = useState<string | undefined>(undefined);

  /**
   * LIFE CYCLE HELPER
   */
  const handlePageLoad = useCallback(() => {
    setIdUpdate(id);
  }, [id]);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageLoad();
  }, [handlePageLoad]);

  return (
    <React.Fragment>
      <PageTitle
        title={!idUpdate ? "Adicionar Registro" : "Atualizar Registro"}
        breadCrumbItems={[
          { label: configs.title, path: configs.path },
          {
            active: true,
            label: !idUpdate ? "Adicionar Registro" : "Atualizar Registro",
            path: !idUpdate ? configs.create : configs.update,
          },
        ]}
      />

      <Row>
        <Col lg={12}>
          <WizardForm idUpdate={idUpdate} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PromotionsCreate;
