import { Row, Col, Card } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Badge,
  Box,
  Button as ChakraButton,
  Table as ChakraTable,
  useToast,
} from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { getActionsPermission } from "../../../helpers/api/auth";
import EditRaffleModal from "./EditRaffleModal";
import CreateRaffleButton from "./CreateRaffleModal";

/**
 * HELPERS
 */

const api = new APICore();
const configs = {
  title: "Rifinha",
  path: "/base/raffles/",
  update: "/bancas/raffles/update/",
  apiBase: "/bancas/raffles/",
};

/**
 * COMPONENT
 */
const Rifa = () => {
  /**
   * HOOKS
   */

  const { permission } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));
  const actions = getActionsPermission({ permission, role: "Unidades" });
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState([]);
  const toast = useToast();

  const navigate = useNavigate();

  /**
   * TABLE DATE COLUMN
   */
  const DateColumn = ({ ...props }: any) => {
    return (
      <React.Fragment>
        {props?.value && format(new Date(props?.value), "dd/MM/yyyy HH:mm")}
      </React.Fragment>
    );
  };

  /**
   * TABLE ACTIONS
   *
   */

  const ActionColumn = ({ ...props }: any) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [objectDisabled, setObjectDisabled] = useState(props?.row?.original);

    const openModal = (raffle: any) => {
      setModalOpen(true);
    };

    const closeModal = () => {
      setModalOpen(false);
    };
    const dispatch = useDispatch<AppDispatch>();

    const object = props?.row?.values;

    /**
     * HANDLERS
     */
    const handleClickDelete = async (id: string) => {
      if (window.confirm("Deseja realmente alterar este registro?") === false)
        return;
      dispatch(toggleLoader(true));
      await api
        .delete(`/bancas/raffles/${objectDisabled?.raffleId}`)
        ?.then((response) => {
          toast({
            title: "Rifa alterada com sucesso.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        })
        .catch((error) => {
          dispatch(toggleLoader(false));
          // Exibe um Toast de erro
          toast({
            title: "Erro ao alterar a rifa.",
            description: error,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
      await fetchData({ page: 1, field: null, order: null, search: null });
      dispatch(toggleLoader(false));
    };

    return (
      <React.Fragment>
        {actions.canWrite && objectDisabled.isEnabled === true && (
          <Box
            onClick={() => openModal(object?.raffleId)}
            className="action-icon"
            cursor={"pointer"}
          >
            <i
              className="mdi mdi-square-edit-outline"
              style={{ color: "#2980b9" }}
            ></i>
          </Box>
        )}
        {actions.canDelete && objectDisabled.isEnabled === true && (
          <Link
            to="#"
            className="action-icon"
            onClick={() => handleClickDelete(object?.raffleId)}
          >
            <i
              className="mdi mdi-close-network-outline"
              style={{ color: "#c0392b" }}
            ></i>
          </Link>
        )}

        {actions.canDelete && objectDisabled.isEnabled === false && (
          <Link
            to="#"
            className="action-icon"
            onClick={() => handleClickDelete(object?.raffleId)}
          >
            <i className="mdi mdi-open-in-app" style={{ color: "#27ae60" }}></i>
          </Link>
        )}

        {/* Modal */}
        <EditRaffleModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          selectedRaffle={props?.row?.original}
          objectDisabled={objectDisabled}
          setObjectDisabled={setObjectDisabled}
        />
      </React.Fragment>
    );
  };

  const columns = [
    {
      Header: "Data de Criação",
      accessor: "createdAt",
      sort: true,
      Cell: DateColumn,
    },
    {
      Header: "Título",
      accessor: "title",
      sort: true,
      Cell: ({ row }) => (
        <button
          style={{
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() =>
            navigate(`/base/raffles/${row.original.raffleId}/info`, {
              state: {
                title: row.original.title,
                type: row.original.type,
              },
            })
          }
        >
          {row.original.title}
        </button>
      ),
    },
    {
      Header: "Tipo",
      accessor: "type",
      sort: true,
      Cell: ({ value }) => {
        switch (value) {
          case "CEM_MIL":
            return "100 mil";
          case "UM_MILHAO":
            return "1 milhão";
          case "DEZ_MILHOES":
            return "10 milhões";
          default:
            return "Indefinido";
        }
      },
    },
    {
      Header: "Visibilidade",
      accessor: "isEnabled",
      sort: true,
      Cell: ({ value }) => {
        switch (value) {
          case true:
            return (
              <>
                <Badge colorScheme="green">HABILITADA</Badge>
              </>
            );
          case false:
            return (
              <>
                <Badge colorScheme="red">DESABILITADA</Badge>
              </>
            );
          default:
            return (
              <>
                <Badge colorScheme="blue">INDEFINIDO</Badge>
              </>
            );
        }
      },
    },
    {
      Header: "Vendas",
      accessor: "status",
      sort: true,
      Cell: ({ value }) => {
        switch (value) {
          case "OPEN":
            return (
              <>
                <Badge colorScheme="green">EM ANDAMENTO</Badge>
              </>
            );
          case "CLOSED":
            return (
              <>
                <Badge colorScheme="red">ENCERRADA</Badge>
              </>
            );
          default:
            return (
              <>
                <Badge colorScheme="blue">INDEFINIDO</Badge>
              </>
            );
        }
      },
    },

    {
      Header: "Valor da cota",
      accessor: "price",
      sort: true,
      Cell: ({ value }) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(value),
    },
    {
      Header: "Ações",
      accessor: "actions",
      sort: false,
      Cell: (props) => <ActionColumn row={props.row} />,
    },
  ];

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();

  /**
   * LIFE CYCLE HELPERS
   */
  const fetchData = async () => {
    dispatch(toggleLoader(true));
    try {
      const response = await api.get("/bancas/raffles");
      const transformedData =
        response.data?.content?.map((raffle) => ({
          raffleId: raffle.raffleId,
          title: raffle.title,
          description: raffle.description,
          image: raffle.imageUrl,
          type: raffle.type,
          status: raffle.status,
          isEnabled: raffle.isEnabled,
          price: raffle.price,
          createdAt: new Date(raffle.createdAt).toISOString(), // Certifique-se de transformar para ISO
        })) ?? [];

      setData(transformedData);
    } catch (e) {
      toast({
        title: "Erro ao buscar dados de registro.",
        description: e.message || "Erro desconhecido",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    dispatch(toggleLoader(false));
  };

  const handlePageLoading = useCallback(async () => {
    await fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  /**
   * ACTIONS
   */

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs?.title,
            path: configs?.path,
            active: true,
          },
        ]}
        title={configs?.title}
      />
      {actions.canCreate && <CreateRaffleButton />}
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Table
                isSearchable={true}
                columns={columns}
                data={data}
                pageSize={12}
                isSortable={true}
                pagination={true}
                tableClass="table-nowrap mt-3 table-hover table-striped"
                tfoot={undefined}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Rifa;
