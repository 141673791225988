import React, { useState } from "react";
import { Card, Row, Col, Form, Button, Spinner, Alert } from "react-bootstrap";
import { APICore } from "../../../helpers/api/apiCore";
import { useToast } from "@chakra-ui/react";

interface QuotaResponse {
  isAvailable: boolean;
  unitData: {
    fullName: string;
    whatsapp: string;
  };
  unitId?: string;
}

interface QuotaCheckProps {
  raffleId: string; // Recebe o raffleId como prop
}

const api = new APICore();

const QuotaCheck: React.FC<QuotaCheckProps> = ({ raffleId }) => {
  const [quotaNumber, setQuotaNumber] = useState<string>("");
  const [result, setResult] = useState<QuotaResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  const handleQuotaCheck = () => {
    setLoading(true);
    setError(null);
    setResult(null);

    api
      .get<QuotaResponse>(`/bancas/raffles/${raffleId}/number/${quotaNumber}`)
      .then((response) => {
        setResult(response.data);
        console.log(response.data);

        // Toast de sucesso
        toast({
          title: "Cota verificada com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        setError("Erro ao verificar o número da cota. Tente novamente.");

        // Toast de erro
        toast({
          title: "Erro ao verificar cota.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false); // Finaliza o carregamento
      });
  };

  return (
    <Card
      className="widget-rounded-circle"
      style={{
        border: "1px solid #333333",
        margin: "20px auto",
        padding: "5px",
      }}
    >
      <Card.Body>
        <Row>
          <Col className="col-12 text-center mb-3">
            <h5 className="text-dark">BUSCAR NÚMERO</h5>
          </Col>
          <Col className="col-12">
            <Form.Group>
              <Form.Control
                type="number"
                placeholder="Digite o número da cota"
                value={quotaNumber}
                onChange={(e) => setQuotaNumber(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col className="col-12 mt-3">
            <Button
              variant="primary"
              onClick={handleQuotaCheck}
              disabled={!quotaNumber || loading}
              className="w-100"
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Verificar"}
            </Button>
          </Col>
          <Col className="col-12">
            {error && (
              <Alert className="mt-2" variant="danger">
                {error}
              </Alert>
            )}
            {result && (
              <Card style={{ marginBottom: "-5px", padding: "1px" }}>
                <Card.Body
                  style={{
                    marginBottom: "-5px",
                    marginTop: "5px",
                  }}
                >
                  {result.isAvailable ? (
                    <Alert variant="danger">
                      Este número ainda não foi adquirido.
                    </Alert>
                  ) : (
                    <div>
                      <p>
                        <strong>Número:</strong> {quotaNumber}
                      </p>
                      <p>
                        <strong>Comprador:</strong>{" "}
                        {result?.unitData?.fullName?.toUpperCase() ||
                          "Não disponível"}
                      </p>
                      <p>
                        <strong>Whatsapp:</strong>{" "}
                        {result?.unitData?.whatsapp ? (
                          <a
                            href={`https://wa.me/${result.unitData.whatsapp.replace(
                              /\D/g,
                              ""
                            )}?text=Parabéns%20você%20é%20o%20ganhador%20com%20o%20número%20da%20cota%20${quotaNumber}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "blue" }}
                          >
                            {result.unitData.whatsapp}
                          </a>
                        ) : (
                          "Não disponível"
                        )}
                      </p>
                      <p>
                        <strong>Unidade:</strong> {result?.unitId}
                      </p>
                    </div>
                  )}
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default QuotaCheck;
